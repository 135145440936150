<template>
    <!-- 添加设备 -->
    <div class="equipment-import">
        <div class="mask"></div>
        <div class="equipmentCard">
            <!-- 头部 -->
            <div class="cardHeader">
                <div>{{ catdTitle }}</div>
                <i class="el-icon-close" @click="cancelEquipmentFun"></i>
            </div>
            <el-form :model="myForm" ref="myForm" :rules="rules">
                <el-form-item label="操作密码" prop="password">
                    <!-- f5XuvuXiTblL7RiW -->
                    <el-input
                        type="password"
                        v-model="myForm.password"
                        placeholder="请输入操作密码"
                    ></el-input>
                </el-form-item>
                <el-form-item label="设备SN和MAC地址" prop="equipSN">
                    <template>
                        <el-radio v-model="radio" label="0">批量导入</el-radio>
                        <el-radio v-model="radio" label="1">单个导入</el-radio>
                    </template>
                    <el-upload
                        v-if="radio === '0'"
                        ref="uploader"
                        action=""
                        :multiple="true"
                        :http-request="httpRequestUpload"
                    >
                        <el-button size="small">
                            上传文件
                        </el-button>
                    </el-upload>
                    <div v-else>
                        <el-input
                            v-model="myForm.equipSN"
                            placeholder="请输入设备SN"
                            class="equipSNSty"
                        ></el-input>
                        <el-input
                            v-model="myForm.mac"
                            placeholder="请输入MAC地址(TS-TS-TS-TS-TS-TS)"
                        ></el-input>
                    </div>
                </el-form-item>
                <el-form-item label="设备类型" prop="type">
                    <el-select
                        class="addressSty"
                        v-model="myForm.type"
                        placeholder="请选择设备类型"
                        filterable
                    >
                        <el-option
                            v-for="(item, index) in enterpriseA"
                            :key="index"
                            :label="item.remark"
                            :value="item.appType"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="所属批次" prop="batch">
                    <el-input
                        v-model="myForm.batch"
                        placeholder="请输入所属批次"
                    ></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input
                        type="textarea"
                        v-model="myForm.remark"
                    ></el-input>
                </el-form-item>
            </el-form>
            <div class="button">
                <el-button @click="cancelEquipmentFun">取 消</el-button>
                <el-button type="primary" @click="determineEquipmentFun"
                    >提 交</el-button
                >
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'equipment-import',
    props: {
        // 标题
        catdTitle: {
            type: String,
            default: '添加设备',
        },
        isAttendance:{
            type:Number,
            default:0
        }
    },
    data() {
        return {
            // 0为批量导入 1为单个添加
            radio: '0',
            // 设备类型 选择
            enterpriseA: [],
            // 表单
            myForm: {
                // 操作密码
                password: '',
                // 文件
                equipSN: '',
                mac: '',
                // 设备类型
                type: '',
                // 所属批次
                batch: '',
                // 备注
                remark: '',
            },
            rules: {
                password: [
                    {
                        required: true,
                        message: '请输入操作密码',
                        trigger: 'blur',
                    },
                ],
                equipSN: [
                    {
                        required: true,
                        message: '请输入设备SN',
                        trigger: 'blur',
                    },
                ],
                mac: [
                    {
                        required: true,
                        message: '请输入MAC地址',
                        trigger: 'blur',
                    },
                ],
                type: [
                    {
                        required: true,
                        message: '请选择设备类型',
                        trigger: 'change',
                    },
                ],
                batch: [
                    {
                        required: true,
                        message: '请输入所属批次',
                        trigger: 'blur',
                    },
                ],
            },
            // 被选中的企业/组织id
            companyId: null,
            myFile: null,
        }
    },
    created() {
        // 设备类型，用于下拉框
        this.getType()
    },
    methods: {
        // 设备类型，用于下拉框
        getType() {
            this.$instance
                .get('/v1/app_version/list', {
                    params: {},
                })
                .then((res) => {
                    if (res.data.status === 200) {
                        this.enterpriseA = res.data.data
                    }
                })
                .catch((error) => {
                    console.log('error: ', error)
                })
        },
        // 覆盖默认的上传行为，可以自定义上传的实现
        httpRequestUpload(file) {
            this.myFile = file.file
        },
        // 点击取消
        cancelEquipmentFun() {
            this.$emit('cancelEquipment-fun')
        },
        // 点击提交
        determineEquipmentFun() {
            if (
                !this.myForm.type ||
                !this.myForm.batch ||
                !this.myForm.password
            ) {
                this.$message({
                    message: '必填项不能为空！',
                    type: 'error',
                })
                return false
            }
            // this.radio 0为批量导入 1为单个添加
            if (
                this.radio === '1' &&
                !this.myForm.equipSN &&
                !this.myForm.mac
            ) {
                this.$message({
                    message: '必填项不能为空！',
                    type: 'error',
                })
                return false
            }

            let value = {
                // 0普通设备 1通勤设备 
                isAttendance: this.isAttendance,
                password: this.$md5(this.myForm.password),
                deviceType: this.myForm.type,
                pc: this.myForm.batch,
                remark: this.myForm.remark,
            }
            let form = new FormData()
            form.append('deviceFile', this.myFile)
            let value2 = {
                sn: this.myForm.equipSN,
                mac: this.myForm.mac,
            }
            let url = '/v1/device/import?'
            let url2 = '/v1/device/add?'

            // this.radio 0为批量导入 1为单个添加
            if (this.radio === '0') {
                this.gatePost(url, value, form)
            } else if (this.radio === '1') {
                this.gatePost(url2, value, this.$qs.stringify(value2))
            }
        },
        // 批量导入设备
        gatePost(url, value, form) {
            this.$instance
                .post(url + this.$qs.stringify(value), form)
                .then((res) => {
                    // console.log('res: ', res.data)
                    if (res.data.status === 200) {
                        this.$emit('determineEquipment-fun')
                        this.$message({
                            message: this.catdTitle + '成功！',
                            type: 'success',
                        })
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.equipment-import {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    .mask {
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.4);
    }
    // 弹窗卡片
    & ::v-deep .equipmentCard {
        width: 520px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        background-color: #fff;
        border-radius: 6px;
        // 头部
        .cardHeader {
            height: 60px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 18px;
            padding: 0 40px;
            box-sizing: border-box;
            border-radius: 6px 6px 0 0;
            // 头部-icon
            .el-icon-close {
                font-size: 24px;
            }
        }
        .el-form {
            padding: 30px 40px 10px;
            border-top: 1px #f0f0f0 solid;
            border-bottom: 1px #f0f0f0 solid;
            .el-form-item {
                margin-bottom: 20px;
                .el-form-item__label {
                    width: 150px !important;
                    padding-right: 20px;
                    line-height: 32px;
                }
                .el-input__inner {
                    line-height: 32px !important;
                }
                .el-form-item__content {
                    margin-left: 150px !important;
                    line-height: 32px;
                    .equipSNSty {
                        margin-bottom: 14px;
                    }
                    .el-button {
                        width: 100px;
                    }
                    .el-select {
                        width: 100%;
                    }
                    input {
                        height: 32px;
                    }
                    i {
                        line-height: 32px;
                    }
                }
            }
        }
    }
    .button {
        padding: 20px 40px;
        display: flex;
        justify-content: flex-end;
        .el-button {
            line-height: 30px;
            padding: 0 20px;
        }
    }
}
</style>
